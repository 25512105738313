import { ConfigProvider, message } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { observer } from 'mobx-react-lite';
import stores from './stores';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store';
import { useEffect, useState } from 'react';
import Sip from './utils/sip';
import getUserInfo from './utils/getUserInfo';
import { viewCompany } from './api/login';
import { blacklistList } from './api/blacklist';

const App = () => {
  const dispatch = useDispatch();
  const { userInfo, isReregister, isCarousel } = useSelector(
    (state: RootState) => state,
  );
  const { ugClient } = stores;
  useEffect(() => {
    if (
      !Sip.userAgent?.isRegistered() &&
      userInfo &&
      userInfo.extension &&
      userInfo.password &&
      isReregister
    ) {
      window.electron.log('sip开始重新注册');
      dispatch({
        type: 'UPDATE_ISREGISTER',
        payload: false,
      });
      dispatch({
        type: 'UPDATE_ISSPIN',
        payload: true,
      });
      Sip.websocket?.disconnect();
      Sip.userAgent?.unregister();
      blacklistList({}).then((res) => {
        if (res.code === '000000') {
          let phones: string[] = [];
          res.data.items?.forEach((item) => {
            phones.push(item.phoneNumber);
          });
          dispatch({
            type: 'UPDATE_BLACKLIST',
            payload: phones,
          });
        }
      });
      Sip.initSip(userInfo.extension, userInfo.password)
        .then(() => {
          dispatch({ type: 'UPDATE_USERINFO', payload: userInfo });
        })
        .catch((e) => {
          message.error(`重新注册失败：${e}`);
        });
    }
    dispatch({
      type: 'UPDATE_ISREREGISTER',
      payload: false,
    });
  }, [dispatch, userInfo, isReregister]);

  useEffect(() => {
    if (!window.localStorage.getItem('theme')) {
      stores.config.setTheme('light');
    }
    if (getUserInfo()?.token) {
      viewCompany()
        .then((res) => {
          if (res.code === '000000') {
            let roleList = {};
            res.data.forEach((res) => {
              roleList = {
                ...roleList,
                [`${res.viKey}`]: res.isEnable === 1,
              };
            });
            dispatch({
              type: 'UPDATE_FUNCROLE',
              payload: roleList,
            });
          }
        })
        .catch(() => { });
    }
    // window.addEventListener('offline', () => {
    //   window.electron.log('断网了');
    //   window.electron.send('reloadConten');
    // });
    wsSocket();
    // 麦克风权限
    let recorder: MediaRecorder;
    navigator.mediaDevices?.getUserMedia &&
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          recorder = new MediaRecorder(stream);
          recorder.stream.getAudioTracks().forEach((track: any) => {
            track.enabled = false;
            track.stop();
          });
          window.electron.log('获取麦克风权限成功');
        })
        .catch((e) => {
          window.electron.log('获取麦克风权限失败', e);
        });
    return () => {
      // window.removeEventListener('offline', () => {});
      Sip.unReg();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let ws: any;
  const [isWsNow, setisWsNow] = useState<boolean>(false);
  const wsSocket = () => {
    if (isWsNow) return;
    ws = new WebSocket(`ws://127.0.0.1:2233`);
    ws.onopen = () => {
      setisWsNow(true);
      ws.send(
        JSON.stringify({
          action: 'getUserInfo',
          payload: null,
        }),
      );
    };
    ws.onerror = () => {
      setisWsNow(false);
    };
  };

  useEffect(() => {
    if (!isCarousel) {
      window.electron.on('boforeStop', () => {
        window.electron.send('stop');
      });
      console.log("A");

      window.electron &&
        window.electron.on('callTarget', (payload: any) => {
          console.log("B");

          let lineCode = getUserInfo()?.selectedLine?.lineCode_Code;
          if (lineCode === 'ugClient') {
            ugClient.makeCall(payload.target);
          } else {
            Sip.callTarget(payload.target, payload.payload);
          }

        })
    }
  }, [isCarousel, ugClient]);

  useEffect(() => {
    // setTimeout(() => { client.init() }, 5000)

  }, [])

  return (
    <ConfigProvider prefixCls={stores.config.theme}>
      <div className='app'>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    </ConfigProvider>
  );
};

export default observer(App);
